import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  img: string;
}

const DeliveryCard: FC<IProps> = ({ img, children }) => (
  <div className={styles.card}>
    <img src={img} alt="" className={styles.card__image} />

    <div className={styles.card__title}>{children}</div>
  </div>
);

export { DeliveryCard };
