import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {
  image: string;
  title: string;
  name: string;
}

const DeliveryPerson: FC<IProps> = ({ image, name, title }) => (
  <div className={styles.person}>
    <div className={styles.person__wrap}>
      <img src={image} className={styles.person__image} alt="" />
      <div className={styles.person__name}>{t(name)}</div>
      <div className={styles.person__title}>{t(title)}</div>
    </div>
  </div>
);

export { DeliveryPerson };
