/* eslint-disable jsx-a11y/alt-text */
import React, { FC } from 'react';
import classNames from 'classnames';
import root from '../../styles.module.scss';
import { t } from '../../../../../i18n';
import { useLocale, usePathPrefix } from '../../../../../constants/hooks';
import { DeliveryHowArrow } from '../../components/DeliveryHowArrow';
import styles from './styles.module.scss';

interface IProps {}

const DeliveryHow: FC<IProps> = () => {
  const locale = useLocale();
  const prefix = usePathPrefix();

  return (
    <div className={classNames(root.screen, root.screen_small, root.how)}>
      <div className={classNames(root.container, root.container_center)}>
        <div className={root.subheading}>{t('cases.delivery.how.title')}</div>

        <div className={classNames(styles.how__description, root.description)}>
          {t('cases.delivery.how.description')}
        </div>

        <div className={styles.how__grid}>
          <img src={`${prefix}/case/delivery/${locale}/how_customer.svg`} />
          <DeliveryHowArrow label="cases.delivery.how.goods_selection" />
          <img src={`${prefix}/case/delivery/${locale}/how_platform.svg`} />
          <DeliveryHowArrow label="cases.delivery.how.order_processing" />
          <img src={`${prefix}/case/delivery/${locale}/how_shop.svg`} />
          <DeliveryHowArrow label="cases.delivery.how.packaging" />
          <img src={`${prefix}/case/delivery/${locale}/how_courier.svg`} />
          <DeliveryHowArrow label="cases.delivery.how.delivery" />
          <img src={`${prefix}/case/delivery/${locale}/how_customer.svg`} />
        </div>
      </div>
    </div>
  );
};

export { DeliveryHow };
