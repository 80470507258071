import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { t } from '../../../../../i18n';
import { DeliveryCard } from '../../components/DeliveryCard';
import { usePathPrefix } from '../../../../../constants/hooks';
import { DeliveryReason } from '../../components/DeliveryReason';

interface IProps {}

const DeliveryReasons: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const reasons = useMemo(() => [...new Array(5)].map((_, i) => i + 1), []);

  return (
    <div className={classNames(root.screen, styles.reasons)}>
      <div className={root.container}>
        <div className={classNames(styles.reasons__title, root.subheading)}>
          {t('cases.delivery.reasons.title')}
        </div>

        <div className={styles.reasons__cards}>
          <DeliveryCard img={`${prefix}/case/delivery/tray.svg`}>
            {t('cases.delivery.reasons.card_1')}
          </DeliveryCard>

          <DeliveryCard img={`${prefix}/case/delivery/drug.svg`}>
            {t('cases.delivery.reasons.card_2')}
          </DeliveryCard>

          <DeliveryCard img={`${prefix}/case/delivery/product.svg`}>
            {t('cases.delivery.reasons.card_3')}
          </DeliveryCard>
        </div>

        <div className={styles.reasons__reasons}>
          {reasons.map((it) => (
            <DeliveryReason
              number={it}
              title={`cases.delivery.reasons.reason_${it}__title`}
              reason={`cases.delivery.reasons.reason_${it}`}
              key={it}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { DeliveryReasons };
