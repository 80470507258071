import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { DeliveryButton } from '../../components/DeliveryButton';
import { t } from '../../../../../i18n';

interface IProps {
  showModal: () => void;
}

const DeliveryRisks: FC<IProps> = ({ showModal }) => (
  <div className={classNames(root.screen, root.screen_small, styles.risks)}>
    <div className={root.container}>
      <div className={classNames(root.subheading, root.center)}>
        {t('cases.delivery.risks.title')}
      </div>

      <div className={styles.risks__grid}>
        <div className={styles.risks__card}>
          {t('cases.delivery.risks.card1')}
        </div>

        <div className={styles.risks__card}>
          {t('cases.delivery.risks.card2')}
        </div>

        <div className={styles.risks__card}>
          {t('cases.delivery.risks.card3')}
        </div>
      </div>

      <div className={root.center}>
        <DeliveryButton onClick={showModal}>
          {t('cases.delivery.find_more')}
        </DeliveryButton>
      </div>
    </div>
  </div>
);

export { DeliveryRisks };
