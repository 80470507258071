/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Spinner } from '../../../../../common/icons/basic';

interface IProps {
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const DeliveryButton: FC<IProps> = ({
  children,
  onClick = () => {},
  isLoading,
  disabled,
}) => (
  <button
    className={cn(styles.button, {
      [styles.button__disabled]: disabled,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
    {isLoading && (
      <div className={styles.spinner__wrapper}>
        <Spinner />
      </div>
    )}
  </button>
);

export { DeliveryButton };
