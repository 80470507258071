import React, { FC } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';

interface IProps {
  label: string;
}

const DeliveryHowArrow: FC<IProps> = ({ label }) => {
  const prefix = usePathPrefix();
  const arrowDesktop = `url('${prefix}/case/delivery/how_arrow.svg')`;
  const arrowMobile = `url('${prefix}/case/delivery/how_arrow_vert.svg')`;

  return (
    <div className={styles.how__arrow}>
      <div
        className={classNames(
          styles.how__arrow__image,
          styles.how__arrow__image_desktop
        )}
        style={{ backgroundImage: arrowDesktop }}
      />

      <div
        className={classNames(
          styles.how__arrow__image,
          styles.how__arrow__image_mobile
        )}
        style={{ backgroundImage: arrowMobile }}
      />
      <span>{t(label)}</span>
    </div>
  );
};

export { DeliveryHowArrow };
