/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classNames from 'classnames';
import { Modal } from '../../../../main/Modal';
import { t } from '../../../../../i18n';
import { DeliveryButton } from '../../components/DeliveryButton';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import {
  useCloseOnEscape,
  usePathPrefix,
} from '../../../../../constants/hooks';
import { sendDeliveryFeedback } from '../../utils/api';
import { TextInputWithIcon } from '../../../../common/TextInputWithIcon';
import formInputs from './constant';
import { AttachInput, AttachInputValue } from '../../../../inputs/AttachInput';
import { OopsPanel } from '../../../../common/OopsPanel';
import { getFromUrl } from '../../../../../utils/getFromUrl';
import {
  fileTypeValidator,
  fileValidatorAfterSubmit,
} from '../../../../../utils/fileTypeValidator';

interface IProps {
  onClose: () => void;
  onSuccess: () => void;
}

const DeliveryContactModal: FC<IProps> = ({ onClose, onSuccess }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_delivery';

  const prefix = usePathPrefix();
  const path = getFromUrl();
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const { name, phone, comment } = inputsState;
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorFile, setErrorFile] = useState('');
  const [file, setFile] = useState<AttachInputValue>({
    name: '',
    content: null,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const validateFields = useCallback(() => {
    const errorsField = {
      name: !name || name.length <= 1,
      phone: !phone || phone.length <= 14,
      file: file.content ? !fileTypeValidator(file) : false,
    };

    fileValidatorAfterSubmit(errorsField.file, file, setErrorFile);

    setErrors(errorsField);

    return !Object.values(errorsField).some((val) => val);
  }, [name, phone, file, setErrors]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nameInput = e.target.name;
    const { value } = e.target;
    if (errors[name])
      setErrors((prevState) => ({ ...prevState, [nameInput]: false }));

    setInputsState((prevState) => ({ ...prevState, [nameInput]: value }));
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);

      try {
        if (!validateFields()) return;
        if (!executeRecaptcha) return;
        const captcha = await executeRecaptcha(formName);

        await sendDeliveryFeedback({
          name,
          phone,
          comment,
          file,
          captcha,
        });

        onSuccess();
        onClose();
        setIsSuccess(true);
      } catch (e) {
        const error = e as AxiosError;
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [validateFields, setIsLoading, onSuccess, onClose, file, errorMessage]
  );

  useEffect(() => {
    if (!file.content) {
      setErrorFile('');
    }
  }, [file]);

  useCloseOnEscape(onClose);

  return (
    <>
      <Modal onClose={onClose}>
        <OopsPanel isOpen={Boolean(errorMessage)} errorMessage={errorMessage} />
        <div className={styles.modal__content}>
          <div className={styles.modal__close} onClick={onClose}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.439362 0.439339C-0.146454 1.02512 -0.146454 1.97489 0.439362 2.56068L6.87869 9.00001L0.468781 15.4099C-0.117035 15.9957 -0.117035 16.9454 0.468781 17.5312C1.0546 18.117 2.0043 18.117 2.59012 17.5312L9.00003 11.1213L15.2886 17.4099C15.8744 17.9957 16.8241 17.9957 17.4099 17.4099C17.9958 16.8241 17.9958 15.8744 17.4099 15.2886L11.1214 9.00001L17.4394 2.68198C18.0252 2.0962 18.0252 1.14646 17.4394 0.560677C16.8535 -0.0251083 15.9038 -0.0251083 15.318 0.560677L9.00003 6.87867L2.5607 0.439339C1.97488 -0.146446 1.02518 -0.146446 0.439362 0.439339Z"
                fill="#55439D"
              />
            </svg>
          </div>

          <div className={root.center}>
            <div
              className={classNames(root.subheading, styles.modal__subheading)}
            >
              {t('cases.delivery.contacts.title')}
            </div>

            <div className={root.description}>
              {t('cases.delivery.contacts.subtitle')}
            </div>
          </div>

          <form
            className={styles.modal__inputs}
            id={formName}
            onSubmit={onSubmit}
          >
            {formInputs.map(({ nameInput, label, icon }) => (
              <TextInputWithIcon
                id={`${path}-${nameInput}`}
                name={nameInput}
                value={inputsState[nameInput] ?? ''}
                handler={onChange}
                label={label}
                icon={`${prefix}${icon}`}
                hasError={errors[nameInput]}
                key={nameInput}
                isSuccess={isSuccess}
              />
            ))}

            <div className={styles.form_footer}>
              <div className={styles.form_footer__file}>
                <AttachInput
                  id={`${path}-attachment`}
                  value={file}
                  handler={setFile}
                  label="cases.delivery.contacts.fileName"
                  error={errorFile}
                />
              </div>
            </div>

            <p className={styles.personel_area}>
              By clicking the SEND button, you consent to the processing of your
              &nbsp;
              <a href="/case/restocare/terms.pdf">personal data</a>
            </p>
            <DeliveryButton isLoading={isLoading} disabled={isLoading}>
              {t('cases.delivery.contacts.send')}
            </DeliveryButton>

            <div id="html_element" />
          </form>
        </div>
      </Modal>
    </>
  );
};

export { DeliveryContactModal };
