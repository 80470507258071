export const getFromUrl = () => {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return;
  }
  const url = window.location.href;
  if (url && url.indexOf('from') !== -1) {
    // eslint-disable-next-line consistent-return
    return url.split('=')[1];
  }
  if (url && url.indexOf('vacancies') !== -1) {
    // eslint-disable-next-line consistent-return
    return url.split('/')[4];
  }
  if (url && url.indexOf('blog') !== -1) {
    // eslint-disable-next-line consistent-return
    return url.split('/')[4];
  }
  if (url && url.indexOf('order') !== -1 && url.indexOf('from') === -1) {
    // eslint-disable-next-line consistent-return
    return 'order-base';
  }
  // eslint-disable-next-line consistent-return
  return window.location.href.replace(/\//g, '');
};
