import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {
  image: string;
  title: string;
  children: string;
}

const DeliveryInteractionCard: FC<IProps> = ({ image, title, children }) => {
  const intl = useIntl();

  return (
    <div className={styles.interaction__card}>
      <img
        src={image}
        alt={intl.formatMessage({ id: title })}
        className={styles.interaction__card__image}
      />

      <div className={styles.interaction__card__title}>{t(title)}</div>

      <div className={styles.interaction__card__description}>{t(children)}</div>
    </div>
  );
};

export { DeliveryInteractionCard };
