import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {
  image: string;
  title: string;
}

const DeliveryStartupStage: FC<IProps> = ({ image, title }) => (
  <div className={styles.stage}>
    <img src={image} className={styles.stage__image} alt="" />
    <div className={styles.stage__title}>{t(title)}</div>
  </div>
);

export { DeliveryStartupStage };
