import React, { FC } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { DeliveryButton } from '../../components/DeliveryButton';
import { usePathPrefix } from '../../../../../constants/hooks';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';

interface IProps {
  title: string;
  showModal: () => void;
}

const DeliveryTitle: FC<IProps> = ({ title, showModal }) => {
  const prefix = usePathPrefix();

  return (
    <div
      className={classNames(root.screen, root.screen_middle, styles.title)}
      style={{ backgroundImage: `url(${prefix}/case/delivery/victoria.jpg)` }}
    >
      <div className={classNames(root.container, styles.title__container)}>
        <div
          className={classNames(root.breadcrumbs, styles.title__breadcrumbs)}
        >
          <Breadcrumbs title={title} />
        </div>
        <div className={styles.title__logo}>
          <img src={`${prefix}/case/delivery/logo.svg`} alt="" />
        </div>

        <div className={styles.title__text}>
          <div className={styles.title__text_bold}>
            {t('cases.delivery.title.text')}
          </div>

          <div className={styles.title__text_condensed}>
            {t('cases.delivery.title.text_condensed')}
          </div>
        </div>

        <div className={styles.title__note}>
          {t('cases.delivery.title.note')}
        </div>

        <DeliveryButton onClick={showModal}>
          {t('cases.delivery.get_advice')}
        </DeliveryButton>
      </div>
    </div>
  );
};

export { DeliveryTitle };
