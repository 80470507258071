import React, { FC, useCallback, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { DeliveryTitle } from '../../../../components/directions/delivery/screens/DeliveryTitle';
import { DeliveryReasons } from '../../../../components/directions/delivery/screens/DeliveryReasons';
import { DeliveryGraphs } from '../../../../components/directions/delivery/screens/DeliveryGraphs';
import { DeliveryInteraction } from '../../../../components/directions/delivery/screens/DeliveryInteraction';
import { DeliveryApp } from '../../../../components/directions/delivery/screens/DeliveryApp';
import { DeliveryHow } from '../../../../components/directions/delivery/screens/DeliveryHow';
import { DeliveryWhere } from '../../../../components/directions/delivery/screens/DeliveryWhere';
import { DeliveryRisks } from '../../../../components/directions/delivery/screens/DeliveryRisks';
import { DeliveryAboutUs } from '../../../../components/directions/delivery/screens/DeliveryAboutUs';
import { DeliveryStartup } from '../../../../components/directions/delivery/screens/DeliveryStartup';
import { DeliveryShowcase } from '../../../../components/directions/delivery/screens/DeliveryShowcase';
import { DeliverySolution } from '../../../../components/directions/delivery/screens/DeliverySolution';
import { DeliveryContactModal } from '../../../../components/directions/delivery/screens/DeliveryContactModal';
import { DeliverySuccessModal } from '../../../../components/directions/delivery/screens/DeliverySuccessModal';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery,
  { locale: string; tag: string; page: number }
>;

const DeliveryPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'en', tag = '', page = 0 },
}) => {
  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );
  const delivery = direction?.node.frontmatter;

  const [isModalShown, setIsModalShown] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  return (
    <ReCaptchaProvider>
      <MainLayout
        locale={locale}
        title={delivery?.seo?.title}
        description={delivery?.seo?.description}
        keywords={delivery?.seo?.keywords}
      >
        <DeliveryTitle title={delivery?.title || ''} showModal={toggleModal} />
        <DeliveryReasons />
        <DeliveryGraphs />
        <DeliveryInteraction />
        <DeliveryApp />
        <DeliveryHow />
        <DeliveryWhere showModal={toggleModal} />
        <DeliveryRisks showModal={toggleModal} />
        <DeliveryAboutUs />
        <DeliveryStartup showModal={toggleModal} />
        <DeliveryShowcase />
        <DeliverySolution showModal={toggleModal} />

        {isModalShown && (
          <DeliveryContactModal
            onClose={toggleModal}
            onSuccess={toggleSuccess}
          />
        )}

        {isSuccessShown && <DeliverySuccessModal onClose={toggleSuccess} />}
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }
  }
`;

export default DeliveryPage;
