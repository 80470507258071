/* eslint-disable consistent-return */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import { useLocale, usePathPrefix } from '../../../../../constants/hooks';
import { DeliveryPhone } from '../../components/DeliveryPhone';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import 'tiny-slider/src/tiny-slider.scss';

const DeliveryShowcase: FC = () => {
  const prefix = usePathPrefix();
  const locale = useLocale();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const slider = require('tiny-slider/src/tiny-slider').tns({
      container: `.${styles.showcase__carousel}`,
      items: 1,
      mouseDrag: true,
      slideBy: 'page',
      gutter: 0,
      controls: true,
      nav: false,
      nextButton: `.${styles.showcase__arrow_right}`,
      prevButton: `.${styles.showcase__arrow_left}`,
      lazyload: false,
      swipeAngle: 45,
    });

    return () => slider.destroy();
  }, [ref.current]);

  return (
    <div className={classNames(root.screen, styles.showcase)} id="referers">
      <div
        className={classNames(
          root.container,
          root.container_center,
          root.center
        )}
      >
        <div className={root.subheading}>
          {t('cases.delivery.showcase.title')}
        </div>

        <div className={styles.showcase__carousel__wrap}>
          <div
            className={classNames(
              styles.showcase__arrow,
              styles.showcase__arrow_left
            )}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.82 33.18L21.66 24L30.82 14.82L28 12L16 24L28 36L30.82 33.18Z"
                fill="#161616"
              />
            </svg>
          </div>

          <div className={styles.showcase__carousel} ref={ref}>
            <DeliveryPhone
              image={`${prefix}/case/delivery/${locale}/primetime.png`}
              title="cases.delivery.showcase.case_1_title"
              info="cases.delivery.showcase.case_1_info"
              url="cases.delivery.showcase.case_1_url"
            />

            <DeliveryPhone
              image={`${prefix}/case/delivery/${locale}/servedin.png`}
              title="cases.delivery.showcase.case_2_title"
              info="cases.delivery.showcase.case_2_info"
              url="cases.delivery.showcase.case_2_url"
            />

            <DeliveryPhone
              image={`${prefix}/case/delivery/${locale}/dostavka.png`}
              title="cases.delivery.showcase.case_3_title"
              info="cases.delivery.showcase.case_3_info"
              url="cases.delivery.showcase.case_3_url"
            />
          </div>

          <div
            className={classNames(
              styles.showcase__arrow,
              styles.showcase__arrow_right
            )}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.18 33.18L26.34 24L17.18 14.82L20 12L32 24L20 36L17.18 33.18Z"
                fill="#161616"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DeliveryShowcase };
