import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { useTc } from '../../../../../constants/hooks';

interface IProps {
  title: string;
  url?: string;
  info: string;
  image: string;
}

const DeliveryPhone: FC<IProps> = ({ url, info, title, image }) => {
  const tc = useTc();

  return (
    <div className={styles.showcase__phone}>
      <div className={styles.showcase__name}>{tc(title)}</div>

      <div className={styles.showcase__text}>
        <div className={styles.showcase__info}>{tc(info)}</div>

        {url && tc(url).trim() ? (
          <div className={styles.showcase__url}>
            <a href={tc(url)} target="_blank" rel="nofollow noreferrer">
              {tc(url)}
            </a>
          </div>
        ) : (
          <div className={styles.showcase__url}>
            {t('cases.delivery.showcase.launch_soon')}
          </div>
        )}
      </div>

      <img src={image} className={styles.showcase__image} alt="" />
    </div>
  );
};

export { DeliveryPhone };
