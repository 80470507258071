import React, { FC } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import { DeliveryButton } from '../../components/DeliveryButton';
import { usePathPrefix } from '../../../../../constants/hooks';
import { DeliveryStartupStage } from '../../components/DeliveryStartupStage';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';

interface IProps {
  showModal: () => void;
}

const DeliveryStartup: FC<IProps> = ({ showModal }) => {
  const prefix = usePathPrefix();

  return (
    <div className={classNames(root.screen, root.startup)}>
      <div
        className={classNames(
          root.container,
          root.container_center,
          root.center
        )}
      >
        <div className={root.subheading}>
          {t('cases.delivery.launch.title')}
        </div>

        <div className={root.description}>
          {t('cases.delivery.launch.subtitle')}
        </div>

        <div className={styles.startup__stages}>
          <DeliveryStartupStage
            image={`${prefix}/case/delivery/cloud.svg`}
            title="cases.delivery.launch.install"
          />

          <img
            src={`${prefix}/case/delivery/startup__arrow.svg`}
            alt=""
            className={styles.startup__arrow}
          />

          <DeliveryStartupStage
            image={`${prefix}/case/delivery/launch.svg`}
            title="cases.delivery.launch.launch"
          />

          <img
            src={`${prefix}/case/delivery/startup__arrow.svg`}
            alt=""
            className={styles.startup__arrow}
          />

          <DeliveryStartupStage
            image={`${prefix}/case/delivery/check.svg`}
            title="cases.delivery.launch.solution"
          />
        </div>

        <div className={styles.startup__opt}>
          {t('cases.delivery.launch.mailout')}
        </div>

        <div className={styles.startup__opt}>
          {t('cases.delivery.launch.landing')}
        </div>

        <div className={styles.startup__opt}>
          {t('cases.delivery.launch.ads')}
        </div>

        <div className={styles.startup__space} />

        <DeliveryButton onClick={showModal}>
          {t('cases.delivery.find_more')}
        </DeliveryButton>
      </div>
    </div>
  );
};

export { DeliveryStartup };
