/* eslint-disable react/no-children-prop */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { t } from '../../../../../i18n';
import { DeliveryInteractionCard } from '../../components/DeliveryInteractionCard';
import { usePathPrefix } from '../../../../../constants/hooks';

interface IProps {}

const DeliveryInteraction: FC<IProps> = () => {
  const prefix = usePathPrefix();

  return (
    <div
      className={classNames(root.screen, root.screen_small, styles.interaction)}
    >
      <div className={root.container}>
        <div
          className={classNames(
            styles.interaction__title,
            root.subheading,
            root.center
          )}
        >
          {t('cases.delivery.interaction.title')}
        </div>

        <div className={styles.interaction__cards}>
          <DeliveryInteractionCard
            image={`${prefix}/case/delivery/bell.svg`}
            title="cases.delivery.interaction.card_1_title"
            children="cases.delivery.interaction.card_1"
          />

          <DeliveryInteractionCard
            image={`${prefix}/case/delivery/content.svg`}
            title="cases.delivery.interaction.card_2_title"
            children="cases.delivery.interaction.card_2"
          />

          <DeliveryInteractionCard
            image={`${prefix}/case/delivery/clients.svg`}
            title="cases.delivery.interaction.card_3_title"
            children="cases.delivery.interaction.card_3"
          />
          <DeliveryInteractionCard
            image={`${prefix}/case/delivery/bonus.svg`}
            title="cases.delivery.interaction.card_4_title"
            children="cases.delivery.interaction.card_4"
          />

          {/* {{#> interaction_card title="Клиентская база"
          image="../assets/images/clients.svg"}} */}
          {/* {{i18n "Можно загрузить базу адресов. Вы будете информировать
          покупателей о скидках, акциях, индивидуальных предложениях"}} */}
          {/* {{/interaction_card}} */}

          {/* {{#> interaction_card title="Бонусная программа"
          image="../assets/images/bonus.svg"}} */}
          {/* {{i18n "Приложение позволит запустить программы лояльности:
          ввести баллы, промокоды"}} */}
          {/* {{/interaction_card}} */}
        </div>
      </div>
    </div>
  );
};

export { DeliveryInteraction };
