import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface IProps {
  number: number;
  title: string;
  reason: string;
}

const DeliveryReason: FC<IProps> = ({ number, title, reason }) => (
  <div className={styles.reason}>
    <div className={styles.reason__number}>{number}</div>

    <div className={styles.reason__text}>
      <div className={styles.reason__title}>{t(title)}</div>

      <div className={styles.reason__description}>{t(reason)}</div>
    </div>
  </div>
);

export { DeliveryReason };
