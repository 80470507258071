import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { DeliveryButton } from '../../components/DeliveryButton';
import root from '../../styles.module.scss';

interface IProps {
  showModal: () => void;
}

const DeliveryWhere: FC<IProps> = ({ showModal }) => (
  <div className={classNames(root.screen, root.screen_small, styles.where)}>
    <div
      className={classNames(root.container, root.container_center, root.center)}
    >
      <div className={root.subheading}>{t('cases.delivery.where.title')}</div>

      <div className={classNames(root.description, styles.where__description)}>
        <p>{t('cases.delivery.where.p1')}</p>
        <br />

        <p>
          {t('cases.delivery.where.p2')}
          <br />
          {t('cases.delivery.where.p3')}
        </p>
      </div>

      <DeliveryButton onClick={showModal}>
        {t('cases.delivery.get_advice')}
      </DeliveryButton>
    </div>
  </div>
);

export { DeliveryWhere };
