/* eslint-disable consistent-return */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import { DeliveryPerson } from '../../components/DeliveryPerson';
import { usePathPrefix } from '../../../../../constants/hooks';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';

interface IProps {}

const DeliveryAboutUs: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const slider = require('tiny-slider/src/tiny-slider').tns({
      container: `.${styles.aboutus__grid}`,
      items: 3,
      mouseDrag: true,
      slideBy: 'page',
      gutter: 0,
      controls: false,
      nav: false,
      center: true,
      loop: false,
      lazyload: false,
      swipeAngle: 45,
      responsive: {
        0: {
          items: 1.5,
          center: true,
          mouseDrag: true,
          loop: true,
          gutter: 16,
        },
        550: {
          items: 1.75,
          center: true,
          mouseDrag: true,
          loop: true,
        },
        768: {
          items: 3.5,
          center: true,
          loop: true,
          mouseDrag: true,
          startIndex: 1,
        },
        1330: {
          disable: true,
        },
      },
    });

    return () => slider.destroy();
  }, [ref.current]);

  return (
    <div className={classNames(root.screen, styles.aboutus)}>
      <div
        className={classNames(
          root.container,
          root.container_center,
          styles.center
        )}
      >
        <div className={root.subheading}>{t('cases.delivery.about.title')}</div>

        <div
          className={classNames(root.description, styles.aboutus__description)}
        >
          {t('cases.delivery.about.subtitle')}
        </div>

        <div className={styles.aboutus__gridwrap}>
          <div className={styles.aboutus__grid} ref={ref}>
            <DeliveryPerson
              image={`${prefix}/case/delivery/pogrebnyak.png`}
              name="cases.delivery.about.pogrebnyak"
              title="cases.delivery.about.director"
            />

            <DeliveryPerson
              image={`${prefix}/case/delivery/getmanov.png`}
              name="cases.delivery.about.getmanov"
              title="cases.delivery.about.csm"
            />

            <DeliveryPerson
              image={`${prefix}/case/delivery/mikhailov.png`}
              name="cases.delivery.about.mikhailov"
              title="cases.delivery.about.techdir"
            />

            <DeliveryPerson
              image={`${prefix}/case/delivery/grebenschikov.png`}
              name="cases.delivery.about.grebenschikov"
              title="cases.delivery.about.artdir"
            />

            <DeliveryPerson
              image={`${prefix}/case/delivery/chernov.png`}
              name="cases.delivery.about.tchernov"
              title="cases.delivery.about.ios"
            />

            <DeliveryPerson
              image={`${prefix}/case/delivery/schvedov.png`}
              name="cases.delivery.about.shvedov"
              title="cases.delivery.about.web"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DeliveryAboutUs };
