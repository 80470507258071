/* eslint-disable jsx-a11y/alt-text */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { useLocale, usePathPrefix } from '../../../../../constants/hooks';
import { t } from '../../../../../i18n';
import 'tiny-slider/src/tiny-slider.scss';
import { Slider } from '../../../../common/Slider';
import useScreen from '../../../../../utils/hooks/useScreen';

interface IProps {}

const DeliveryApp: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const locale = useLocale();
  const { isDesktopSmall } = useScreen();

  const sliderProps = {
    mouseDrag: true,
    gutter: 24,
    nav: false,
    center: true,
    loop: false,
    lazyload: false,
    controls: true,
    items: 1,
    swipeAngle: 45,
  };

  const sliderItemsDesktop = [
    {
      name: t('cases.delivery.app.for_client'),
      images: [
        `${prefix}/case/delivery/${locale}/client_1.png`,
        `${prefix}/case/delivery/${locale}/client_2.png`,
        `${prefix}/case/delivery/${locale}/client_3.png`,
      ],
    },
    {
      name: t('cases.delivery.app.for_constructor'),
      images: [
        `${prefix}/case/delivery/${locale}/constructor_1.png`,
        `${prefix}/case/delivery/${locale}/constructor_2.png`,
        `${prefix}/case/delivery/${locale}/constructor_3.png`,
      ],
    },
    {
      name: t('cases.delivery.app.for_courier'),
      images: [
        `${prefix}/case/delivery/${locale}/courier_1.png`,
        `${prefix}/case/delivery/${locale}/courier_2.png`,
        `${prefix}/case/delivery/${locale}/courier_3.png`,
      ],
    },
  ];

  const sliderItemsMobile = [
    {
      name: t('cases.delivery.app.for_client'),
      imageUrl: `${prefix}/case/delivery/${locale}/client_1.png`,
    },
    {
      name: t('cases.delivery.app.for_client'),
      imageUrl: `${prefix}/case/delivery/${locale}/client_2.png`,
    },
    {
      name: t('cases.delivery.app.for_client'),
      imageUrl: `${prefix}/case/delivery/${locale}/client_3.png`,
    },
    {
      name: t('cases.delivery.app.for_constructor'),
      imageUrl: `${prefix}/case/delivery/${locale}/constructor_1.png`,
    },
    {
      name: t('cases.delivery.app.for_constructor'),
      imageUrl: `${prefix}/case/delivery/${locale}/constructor_2.png`,
    },
    {
      name: t('cases.delivery.app.for_constructor'),
      imageUrl: `${prefix}/case/delivery/${locale}/constructor_3.png`,
    },
    {
      name: t('cases.delivery.app.for_courier'),
      imageUrl: `${prefix}/case/delivery/${locale}/courier_1.png`,
    },
    {
      name: t('cases.delivery.app.for_courier'),
      imageUrl: `${prefix}/case/delivery/${locale}/courier_2.png`,
    },
    {
      name: t('cases.delivery.app.for_courier'),
      imageUrl: `${prefix}/case/delivery/${locale}/courier_3.png`,
    },
  ];

  return (
    <div
      className={classNames(
        root.screen,
        root.screen_small,
        root.screen_blank,
        styles.app
      )}
      id="product"
    >
      <div
        className={classNames(
          root.container,
          root.container_center,
          styles.app__container
        )}
      >
        <div
          className={classNames(
            styles.app__title,
            root.subheading,
            root.center
          )}
        >
          {t('cases.delivery.app.title')}
        </div>

        <div className={root.description}>
          {t('cases.delivery.app.description')}
        </div>
      </div>

      <div className={styles.carousel}>
        {isDesktopSmall ? (
          <Slider
            key="smallDesktop"
            color="#55439D"
            className={styles.carousel__slider}
            sliderProps={sliderProps}
          >
            {sliderItemsMobile.map((item) => (
              <div key={item.imageUrl} className={styles.carousel__slide}>
                <div className={styles.carousel__label}>{item.name}</div>
                <div className={styles.carousel__images__container}>
                  <img src={item.imageUrl} />
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <Slider
            key="desktop"
            color="#55439D"
            className={styles.carousel__slider}
            sliderProps={sliderProps}
          >
            {sliderItemsDesktop.map((item, index) => (
              <div
                key={`desktop-${index + 1}`}
                className={styles.carousel__slide}
              >
                <div className={styles.carousel__label}>{item.name}</div>
                <div className={styles.carousel__images__container}>
                  {item.images.map((imgUrl) => (
                    <img key={imgUrl} src={imgUrl} />
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export { DeliveryApp };
