import React, { FC } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import root from '../../styles.module.scss';
import styles from './styles.module.scss';
import {
  useLocale,
  usePathPrefix,
  useTc,
} from '../../../../../constants/hooks';

interface IProps {}

const DeliveryGraphs: FC<IProps> = () => {
  const pathPrefix = usePathPrefix();
  const locale = useLocale();
  const tc = useTc();

  return (
    <div className={classNames(root.screen, styles.graphs)}>
      <div className={root.container}>
        <div className={styles.graphs__text}>
          <div className={classNames(root.subheading, styles.graphs__title)}>
            {t('cases.delivery.graphs.title')}
          </div>

          <div className={styles.graphs__description}>
            {t('cases.delivery.graphs.description')}
          </div>
        </div>

        <div className={classNames(styles.graphdata, styles.graphdata_desktop)}>
          <div className={styles.graphdata__graph}>
            <img
              src={`${pathPrefix}/case/delivery/${locale}/graphs.svg`}
              alt={tc('cases.delivery.graphs.graph_1_alt')}
              width="100%"
            />
          </div>
        </div>

        <div className={classNames(styles.graphdata, styles.graphdata_mobile)}>
          <div className={styles.graphdata__graph}>
            <img
              src={`${pathPrefix}/case/delivery/${locale}/graphs_mobile.svg`}
              alt={tc('cases.delivery.graphs.graph_1_alt')}
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DeliveryGraphs };
