import React, { FC } from 'react';
import classNames from 'classnames';
import { DeliveryButton } from '../../components/DeliveryButton';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { t } from '../../../../../i18n';

interface IProps {
  showModal: () => void;
}

const DeliverySolution: FC<IProps> = ({ showModal }) => (
  <div
    className={classNames(root.screen, root.screen_small, styles.solution)}
    id="price"
  >
    <div
      className={classNames(
        root.container,
        root.container__center,
        root.center
      )}
    >
      <div className={classNames(root.subheading, styles.solution__title)}>
        {t('cases.delivery.solution.title')}
      </div>

      <div className={styles.solution__grid}>
        <div className={styles.solution__card}>
          <div className={styles.solution__subtitle}>
            {t('cases.delivery.solution.saas.title')}
          </div>

          <p>{t('cases.delivery.solution.saas.p1')}</p>
          <p>{t('cases.delivery.solution.saas.p2')}</p>
          <p>{t('cases.delivery.solution.saas.p3')}</p>
          <p>{t('cases.delivery.solution.saas.p4')}</p>
        </div>

        <div className={styles.solution__card}>
          <div className={styles.solution__subtitle}>
            {t('cases.delivery.solution.box.title')}
          </div>

          <p>{t('cases.delivery.solution.box.p1')}</p>
          <p>{t('cases.delivery.solution.box.p2')}</p>
          <p>{t('cases.delivery.solution.box.p3')}</p>
          <p>{t('cases.delivery.solution.box.p4')}</p>
        </div>

        <div className={styles.solution__card}>
          <div className={styles.solution__subtitle}>
            {t('cases.delivery.solution.custom.title')}
          </div>

          <p>{t('cases.delivery.solution.custom.p1')}</p>
          <p>{t('cases.delivery.solution.custom.p2')}</p>
          <p>{t('cases.delivery.solution.custom.p3')}</p>
          <p>{t('cases.delivery.solution.custom.p4')}</p>
        </div>
      </div>

      <DeliveryButton onClick={showModal}>
        {t('cases.delivery.find_more')}
      </DeliveryButton>
    </div>
  </div>
);

export { DeliverySolution };
